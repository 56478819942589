import { appEnv } from '../appEnv';
import { createFlagsProvider, PertsistFlags } from './flags';

type AppFlags = typeof appEnv.flags;

const appFlagsPersist: PertsistFlags<AppFlags> = {
   chartingServiceWs: true,
   chartingServiceGridEditor: true,
   chartingServiceGridFluid: false,
   debugShowTilePanel: true,
   debugShow: true,
   debugPersist: true,
   debugEnabled: false,
   debugShowGridPanel: false,
   apiDelay: true,
   mockedNodes: true,
   mockedNodesEmpty: true,
   useCsDebugApi: true,
   forceStandard: true,
   forceSingleOrg: true,
   forceUserNotDeletable: true,
   debugLayout: true,
   debugDevTools: true,
   debugDevToolsRedux: true,
   devMockedDashboards: 'localhost',
   devFeViews: 'localhost',
   useCsProdData: true,
   useDraftData: true,
   useDots: true,
   useCsAesQuery: true,
};

const { useFlags, useSetFlag, FlagsProvider } = createFlagsProvider<AppFlags>(appEnv.flags, appFlagsPersist);

export { useFlags, useSetFlag, FlagsProvider };
