import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type KeyOfString<T> = keyof T extends string ? keyof T : never;

type Flags = {
   debugEnabled: boolean;
   debugPersist: boolean;
   debugShow: boolean;
   [key: string]: boolean;
};

export type PertsistFlags<Type> = {
   [Property in keyof Type]: boolean | 'localhost';
};

export function createFlagsProvider<T extends Flags>(envFlags: Flags, options: PertsistFlags<T>) {
   function rawSetFlag(name: KeyOfString<T>, value: boolean) {
      envFlags[name] = value;
   }

   function flagsSave(flags: Flags) {
      const savedFlags: Partial<PertsistFlags<Flags>> = {};
      for (const k of Object.keys(options)) {
         if (
            options[k as keyof PertsistFlags<Flags>] === true ||
            (options[k as keyof PertsistFlags<Flags>] === 'localhost' && window.location.hostname === 'localhost')
         ) {
            savedFlags[k as keyof PertsistFlags<Flags>] = flags[k as keyof typeof envFlags];
         }
      }
      localStorage.setItem('flags', JSON.stringify(savedFlags));
   }

   function flagsRemove() {
      localStorage.removeItem('flags');
   }

   function flagLoad(
      flagsParsed: typeof envFlags | undefined,
      flags: typeof envFlags,
      flag: keyof PertsistFlags<Flags>,
   ) {
      if (flagsParsed && flagsParsed[flag] !== undefined) flags[flag] = flagsParsed[flag];
   }

   function flagsLoad(flags: typeof envFlags) {
      try {
         const flagsSaved: string | null = localStorage?.getItem('flags');
         if (flagsSaved) {
            const flagsParsed = JSON.parse(flagsSaved) as typeof envFlags;
            for (const k of Object.keys(options)) {
               if (
                  options[k as keyof PertsistFlags<Flags>] === true ||
                  (options[k as keyof PertsistFlags<Flags>] === 'localhost' && window.location.hostname === 'localhost')
               )
                  flagLoad(flagsParsed, flags, k as keyof PertsistFlags<Flags>);
            }
         }
      } catch {
         //
      }
   }

   //if not prod reload persisted flags
   if (envFlags.debugEnabled) {
      flagsLoad(envFlags);
   }

   const FlagsContext = createContext({
      flags: envFlags as T,
      setFlag: rawSetFlag,
   });

   const FlagsProvider: React.FC = ({ children }) => {
      const [flags, setFlags] = useState(envFlags as T);

      const setFlag = useCallback((name: KeyOfString<T>, value: boolean) => {
         rawSetFlag(name, value);

         if (envFlags.debugEnabled && envFlags.debugPersist) {
            flagsSave(envFlags);
         }

         if (envFlags.debugEnabled && name === 'debugPersist' && !value) {
            flagsRemove();
         }

         setFlags((f) => ({ ...f, [name]: value }));
      }, []);

      useEffect(() => {
         let mounted = true;
         const onkeydown = (k: KeyboardEvent) => {
            if (mounted && k.target === document.body && k.key === 'd') {
               setFlag('debugShow' as KeyOfString<T>, !flags.debugShow);
            }
         };
         flags.debugEnabled && document.addEventListener('keydown', onkeydown);
         return () => {
            mounted = false;
            flags.debugEnabled && document.removeEventListener('keydown', onkeydown);
         };
      }, [flags.debugShow]); //eslint-disable-line

      const value = useMemo(() => ({ flags, setFlag }), [flags, setFlag]);

      return <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>;
   };

   const useFlags = () => {
      return useContext(FlagsContext).flags;
   };

   const useSetFlag = () => {
      return useContext(FlagsContext).setFlag;
   };

   return {
      FlagsProvider,
      useFlags,
      useSetFlag,
   };
}
